import _ from "lodash";
import type { stringOfAny } from "src/types";
import * as lib from ".";

// Cache for compiled templates
const compiler_cache = {};
const async_compiler_cache = {};

export function get_evaluatable_context(opts: { data: any; pb_element_id?: string }) {
    // console.log('APP_CONFIG store value:', lib.getStoreValue(lib.APP_CONFIG));

    const init = {
        data: opts.data,
        ...(opts?.data?.store_value
            ? {
                ds: opts.data.store_value,
                el: {
                    ...opts.data.store_value,
                    self:
                        typeof opts.pb_element_id === "string" && opts.pb_element_id in opts.data.store_value
                            ? opts.data.store_value[opts.pb_element_id]
                            : undefined
                }
            }
            : {}),
        ...opts.data
    };

    return [
        ["plugin", lib.PLUGIN_ENDPOINTS],
        ["_", _],
        ["lib", lib],
        ["window", window],
        ...(!init.APP_CONFIG ? [["APP_CONFIG", lib.getStoreValue(lib.APP_CONFIG)]] : [])
    ].reduce((p, [k, v]) => (k ? { ...p, [k]: v } : p), init);
}

function getCompiledTemplate(str: string, opts = {}) {
    if (str in compiler_cache) return compiler_cache[str];
    const compiled = _.template(str, opts);
    compiler_cache[str] = compiled;
    return compiled;
}

function getCompiledTemplateAsync(str: string, opts = {}) {
    if (str in async_compiler_cache) return async_compiler_cache[str];
    const compiled = _.templateAsync(str, opts);
    async_compiler_cache[str] = compiled;
    return compiled;
}

function resolveDollarColonReference(str: string, context: any): string {
    try {
        return lib.accessFromString(context, str);
    } catch (e) {
        return undefined;
    }
}

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

/**
 * Convert a simple stringed template with a data object
 * @param {string} template
 * @param {stringOfAny} data Data keyed by replacers in template
 * @returns {string}
 * @example
 * 
 * const template = "$:Employee.0.firstname, $:Employee.0.lastname";
 * 
 * const data = {
 *   Employee: [
 *     {
 *       id: 123,
 *       firstname: 'foo',
 *       lastname: 'bar'
 *     }
 *   ]
 * };
 * 
 * const out = converTemplate(template, data);
 * 
 * console.log(out);
 * outputs:
 * foo, bar
 */
export function convertTemplate(
    template: string,
    data: stringOfAny,
    opts: { throw_errors?: boolean; pb_element_page_uid?: string; pb_element_id?: string } = {}
): string {
    // console.log('Converting template (SYNC):', template);
    try {
        if (!template) return "";

        const context = get_evaluatable_context({
            data,
            pb_element_id: opts?.pb_element_id
        });

        // First handle $: references
        let processedTemplate = template.replace(/\$:([A-Za-z0-9|\.|_]+)/g, (match, path) => {
            const value = resolveDollarColonReference(path, context);
            return value === undefined ? "" : value;
        });

        // Then handle {{}} template syntax without modification
        const compiled = getCompiledTemplate(processedTemplate);
        return compiled(context);
    } catch (e) {
        if (opts.throw_errors) {
            throw e;
        }
        if (!(e instanceof ReferenceError)) {
            console.error(e);
        }
        return "";
    }
}

export async function convertTemplateAsync(
    template: string,
    data: stringOfAny,
    opts: { throw_errors?: boolean; pb_element_page_uid?: string; pb_element_id?: string } = {}
): Promise<string> {
    // console.log('Converting template (ASYNC):', template);
    try {
        if (!template) return "";

        const context = get_evaluatable_context({
            data,
            pb_element_id: opts?.pb_element_id
        });

        // First handle $: references
        let processedTemplate = template.replace(/\$:([A-Za-z0-9|\.|_]+)/g, (match, path) => {
            const value = resolveDollarColonReference(path, context);
            return value === undefined ? "" : value;
        });

        // Then handle {{}} template syntax without modification
        const compiled = getCompiledTemplateAsync(processedTemplate);
        return compiled(context);
    } catch (e) {
        if (opts.throw_errors) {
            throw e;
        }
        if (!(e instanceof ReferenceError)) {
            console.error(e);
        }
        return "";
    }
}

export const CONVERT_REGEX = /\$\:[A-Za-z0-9|\.|_]*/g;
