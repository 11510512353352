<script lang="ts">export let header = "No Header or slot header";
export let bodyClass = "";
export let bodyStyle = "";
export let headerClass = "";
</script>

<style lang="scss">.card {
  border: none;
  border-radius: 0;
  margin: 0;
}
.card .card-header {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0.25rem 0.5rem;
}
.card .card-body {
  border: none;
  border-radius: 0;
  margin: 0;
}

.card-body-no-padding > .card-body {
  padding-right: 0px;
  padding-bottom: 0px;
}</style>

<div class="card card-body-no-padding">
	<div class="card-header {headerClass} pt-0 pb-0">
		<slot name="header">{header}</slot>
	</div>
	<div class="card-body {bodyClass}" style={bodyStyle}>
		<slot />
	</div>
</div>
